import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '~/components/Layout'
import { BooksForPageQuery, GatsbyImageSharpFluidFragment, IGasbyPageProps, IPost } from '~/types'
import SEO from '~/components/SEO'
import BookListItem from '~/components/BookListItem'
import BooksContainer from '~/components/BooksContainer'
import Pagination from '~/components/Pagination'
import { formatPostListFromData } from '~/utils/post'
import styled from 'styled-components'
import { height4x3 } from '~/utils/styling'

const Table = styled.table`
  font-size: .5em;
  border-collapse: collapse;
  td {
    border: 1px solid #ccc;
    padding: .25em
  }
`

interface IPostListTemplatePageContext {
  currentPage: number
  numPages: number
}

interface IBookListTemplateProps extends IGasbyPageProps {
  data: BooksForPageQuery
  pageContext: IPostListTemplatePageContext
}

const Subheading = styled.h3`
  color: #999;
  text-transform: uppercase;
  font-size: 20px;
`

const BookListTemplate: React.FC<IBookListTemplateProps> = ({
  data,
  uri,
  pageContext,
}) => {
  const books = formatPostListFromData(data) //.sort((a,b) => a.year === b.year ? a.title.localeCompare(b.title) : b.year - a.year)
  // const booksMap = new Map()

  // data.books.nodes.forEach(e => booksMap.set(e.urlKey, e))

  const { currentPage, numPages } = pageContext
  return (
    <Layout uri={uri}>
      <Table>
        <tbody>
          {books.map((book, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td width="10%">
                <Img
                  fluid={book.cover as GatsbyImageSharpFluidFragment}
                  style={{ maxHeight: `${height4x3}px` }}
                  imgStyle={{
                    objectFit: 'contain',
                    // border: `3px solid white`,
                    // maxHeight: `${height4x3}px`,
                  }}
                />
              </td>
              <td><strong>{book.bookInfo.title}</strong></td>
              <td>{book.bookInfo.description}</td>
              <td>{book.year}</td>
              <td>{book.bookInfo.publisher}</td>
              <td>{book.bookInfo.isbn}</td>
              <td>{book.bookInfo.grif}</td>
              <td>{book.bookInfo.pageCount}</td>
              <td>{book.bookInfo.paper}</td>
              <td>{book.bookInfo.binding}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <SEO
        title="Список книг (закртыая страница)"
        robots="noindex,nofollow"
        description=""
      ></SEO>
    </Layout>
  )
}

export default BookListTemplate

export const query = graphql`
  query booksForBooklist($contentWidth: Int!) {
    allMdx(
      sort: {
        fields: [frontmatter___year, frontmatter___title]
        order: [DESC, ASC]
      }
      filter: {
        frontmatter: { draft: { ne: true } }
        fields: { urlPath: { ne: null }, source: { eq: "books" } }
      }
    ) {
      edges {
        node {
          ...PostDataMdxFragment
        }
      }
    }
  }
`
